<template>
  <div class="border rounded card-body">
    <div :class="`text-${cardType}`" class="font-size-24 font-weight-bold text-uppercase mb-2">
      <slot name="value"></slot>
    </div>
    <div class="font-size-12 text-uppercase">
      <slot name="title"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardType: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>

</style>
